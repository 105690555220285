* {
  --height: calc(100vh - 64px); /* минус высота header*/
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  color: inherit;
  background: none;
  text-decoration: none;
  border: none;
  font-family: inherit;
}

#root {
  height: 100vh;
  width: 100vw;
}

.ant-layout-content {
  flex: auto;
  min-height: 0;
  height: var(--height);
}

.ant-layout-header {
  padding: 0;
  background: rgba(132, 132, 132, .94);
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: rgba(132, 132, 132, .94);
}

.center {
  height: 85px;
  line-height: 85px;
  text-align: center;
}

.targets-wrapper {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #3F90DF;
}

.whatWeDid {
  display: grid;
  gap: 1rem;
  text-align: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.targets {
  background-image: url(https://esg.aeve.ru/img/targets.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
}

.service-card {
  height: 100%; /* Устанавливаем фиксированную высоту */
  display: flex;
  flex-direction: column;
}

.service-card .ant-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.services-container {
  padding: 16px; /* Добавляем отступы от краев контейнера */
}

.service-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.service-card .ant-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu {
  background-color: transparent; /* Прозрачный фон для пунктов меню */
  border-right: none; /* Убираем правую границу */
}

.menu .ant-menu-item {
  padding-left: 24px !important; /* Отступ слева для пунктов меню */
}

.menu .ant-menu-item-selected {
  background-color: #1890ff; /* Цвет фона выбранного пункта меню */
}

.scrollable-content {
  overflow-y: auto; /* Добавьте прокрутку при необходимости */
}

.ant-layout .ant-layout-has-sider {
  height: var(--height);
}

.about-container, .news-container {
  padding: 16px;
}

.ant-layout-footer {
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  position: fixed;
  top: calc(var(--height) + 18px);
  width: 100%;
  background: #f0f2f5;
}

.ant-menu-overflow {
  display: flex;
  justify-content: space-around;
}

.ant-layout-header {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px; /* Измените отступы по своему усмотрению */
  background-color: #ffffff; /* Цвет фона */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень */
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.logo svg {
  width: 80px; /* Размер логотипа */
  height: auto;
  fill: #ffffff; /* Цвет логотипа */
}

.main-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.main-menu li {
  margin-right: 20px; /* Расстояние между пунктами меню */
}

.main-menu a {
  text-decoration: none;
  color: #333333; /* Цвет ссылок */
  font-weight: bold;
  transition: color 0.3s;
}

.main-menu a:hover {
  color: #4CAF50; /* Цвет ссылок при наведении */
}

.callback-button {
  background-color: #4CAF50; /* Цвет кнопки */
  color: #ffffff; /* Цвет текста кнопки */
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.callback-button:hover {
  background-color: #45a049; /* Цвет кнопки при наведении */
}


